import { render, staticRenderFns } from "./Employees.vue?vue&type=template&id=245523b0&scoped=true&"
import script from "./Employees.vue?vue&type=script&lang=ts&"
export * from "./Employees.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245523b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VExpansionPanelContent,VList})
